import React from "react";
import logo from "../../assets/images/coind-logo.png";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./Home.css";

export default function Home() {
  return (
    <Container>
      <Row style={{ height: "30vh" }}></Row>
      <Row style={{ height: "20vh" }}>
        <Col xs={12}>
          <img
            className="logo"
            src={logo}
            alt="logo"
            style={{ height: "13vh" }}
          />
        </Col>
      </Row>
      <Row style={{ height: "15vh" }}>
        <Col lg={2} className="d-none d-lg-block"></Col>
        <Col xs={12} sm={3} md={3} lg={2}>
          <div
            style={{
              color: "cadetBlue",
              fontFamily: "TEKO, sans-serif",
              fontSize: "30px",
              lineHeight: "90%",
            }}
          >
            blockchain
          </div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2}>
          <div
            style={{
              color: "cadetBlue",
              fontFamily: "TEKO, sans-serif",
              fontSize: "30px",
              lineHeight: "90%",
            }}
          >
            defi
          </div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2}>
          <div
            style={{
              color: "cadetBlue",
              fontFamily: "TEKO, sans-serif",
              fontSize: "30px",
              lineHeight: "90%",
            }}
          >
            metaverse
          </div>
        </Col>
        <Col xxs={12} sm={3} md={3} lg={2}>
          <div
            id="p2e"
            style={{
              color: "cadetBlue",
              fontFamily: "TEKO, sans-serif",
              fontSize: "30px",
              lineHeight: "90%",
            }}
          >
            play-to-earn
          </div>
        </Col>
      </Row>
      <Row style={{ height: "10vh" }}></Row>
      <Row style={{ height: "25vh" }}>
        <div style={{ fontSize: "40px" }}>COMING 2022</div>
      </Row>
    </Container>
  );
}
